import { parse } from 'node-html-parser';
export default (data) => {
    let allPosts = [];
    let mainPost = null;
    let postOffsetStart = 0;
    for(var post of data) {
        // Extract basic fields
        const title = post.title.rendered;
        const link = post.link;
        const pubDate = post.date;
        const pubDateFormat = new Date(pubDate).toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric'
        });

        // Use excerpt as description; fallback to content if needed
        const desc =
            (post.excerpt && post.excerpt.rendered) ||
            (post.content && post.content.rendered) ||
            "";

        // Get thumbnail images from featured media if available
        let thumb = "";
        let thumbLarge = "";
        if (
            post._embedded &&
            post._embedded["wp:featuredmedia"] &&
            post._embedded["wp:featuredmedia"].length
        ) {
            const media = post._embedded["wp:featuredmedia"][0];
            if (media.media_details && media.media_details.sizes) {
                if (media.media_details.sizes['thumbnail_1280x720']) {
                    thumbLarge = media.media_details.sizes['thumbnail_1280x720'].source_url;
                }
                if(media.media_details.sizes['thumbnail_270x270']) {
                    thumb = media.media_details.sizes['thumbnail_270x270'].source_url;
                }

                //thumbnail_1280x720
                if (media.media_details.sizes['medium_large'] && !thumbLarge) {
                    thumbLarge = media.media_details.sizes['medium_large'].source_url;
                }

                if (media.media_details.sizes['thumbnail_1600x900']) {
                    thumbLarge = media.media_details.sizes['thumbnail_1600x900'].source_url;
                } else {
                    if(thumbLarge && thumbLarge.length){
                        thumb = thumbLarge;
                    }; // fallback if large size is missing
                }
            }
        }

        // Extract category information if available (requires _embed for terms)
        let category = "";
        let categoryLink = "";
        if (post._embedded && post._embedded["wp:term"]) {
            // WP REST API returns an array of term arrays. Categories usually come first.
            const categoryTerms = post._embedded["wp:term"].find(
                termArray =>
                    termArray.length > 0 && termArray[0].taxonomy === "category"
            );
            if (categoryTerms && categoryTerms.length) {
                category = categoryTerms[0].name;
                categoryLink = categoryTerms[0].link;
            }
        }

        // Get author details from embedded author data
        let blogAuthorLink = "";
        let blogAuthor = "";
        if (post._embedded && post._embedded.author && post._embedded.author.length) {
            blogAuthor = post._embedded.author[0].name;
            blogAuthorLink = post._embedded.author[0].link;
        }

        // Return the object in your desired format
        let item = {
            title,
            link,
            url: link,
            pubDate,
            desc,
            thumb,
            thumbLarge,
            thumbDefault: thumb,
            category,
            categoryLink,
            pubDateFormat,
            blogAuthorLink,
            author: blogAuthor ? blogAuthor.trim() : ""
        };
            allPosts.push(item);
    }
    return {allPosts,mainPost,postOffsetStart};
}

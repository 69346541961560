import { render, staticRenderFns } from "./TopNavUniversalExternal.vue?vue&type=template&id=c59d9222&scoped=true"
import script from "./TopNavUniversalExternal.vue?vue&type=script&lang=js"
export * from "./TopNavUniversalExternal.vue?vue&type=script&lang=js"
import style0 from "./TopNavUniversalExternal.vue?vue&type=style&index=0&id=c59d9222&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../web-components/node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c59d9222",
  null
  
)

export default component.exports
<template>
<div class="top-nav" @click.stop="() => {

}" @mouseleave="closeNav">
  <div class="fixed-top-right" @click="closeNav">
    <CloseX @click="closeNav" :close="closeNav"></CloseX>
  </div>
  <div class="inner">
    <div class="logo-and-name" @click.stop="closeNav">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.2606 10.5948V17.8852C15.2606 18.0797 15.0662 18.2741 14.8718 18.2741H13.3165C13.1221 18.2741 12.9277 18.0797 12.9277 17.8852V10.5948C12.9277 10.4004 13.1221 10.206 13.3165 10.206H14.8718C15.1634 10.206 15.2606 10.4004 15.2606 10.5948ZM11.0808 10.206H8.74784C8.55343 10.206 8.35902 10.4004 8.35902 10.5948V13.2194C8.35902 13.4138 8.55343 13.6082 8.74784 13.6082H11.0808C11.2752 13.6082 11.4696 13.4138 11.4696 13.2194V10.5948C11.4696 10.4004 11.2752 10.206 11.0808 10.206ZM11.0808 14.8719H8.74784C8.55343 14.8719 8.35902 15.0663 8.35902 15.2607V17.8852C8.35902 18.0797 8.55343 18.2741 8.74784 18.2741H11.0808C11.2752 18.2741 11.4696 18.0797 11.4696 17.8852V15.2607C11.4696 14.9691 11.2752 14.8719 11.0808 14.8719ZM22.7454 22.1623C22.6482 22.3567 22.4538 22.5511 22.1622 22.7455L22.065 22.8427C19.8293 24.1064 2.91552 24.1064 1.55465 22.6483C1.36024 22.5511 1.26303 22.3567 1.16583 22.2595C-0.292254 20.7042 -0.292254 3.01283 1.16583 1.55475C1.26303 1.45754 1.36024 1.26313 1.55465 1.16593C2.91552 -0.292151 19.8293 -0.389356 22.065 0.971518C22.065 0.971518 22.1622 0.971518 22.1622 1.06872C22.4538 1.26313 22.6482 1.45755 22.7454 1.65196C24.1063 3.79047 24.1063 19.9266 22.7454 22.1623ZM16.7187 18.7601C17.3019 17.788 17.3019 10.5948 16.7187 9.62279C16.7187 9.52559 16.6215 9.42838 16.4271 9.33118C15.455 8.74795 7.87299 8.74795 7.28976 9.42838C7.19256 9.42838 7.09535 9.52559 7.09535 9.62279C6.41492 10.3032 6.41492 18.1769 7.09535 18.8573C7.09535 18.9545 7.19256 18.9545 7.28976 19.0517C7.87299 19.7321 15.455 19.7321 16.5243 19.1489C16.5243 19.1489 16.5243 19.1489 16.6215 19.1489C16.6215 18.9545 16.7187 18.8573 16.7187 18.7601ZM20.4125 7.8731C19.246 7.28987 13.5109 4.17929 11.8584 3.20724C10.2059 4.17929 4.47081 7.28987 3.30434 7.8731C3.79037 8.26192 4.66522 8.74795 4.95683 8.84515C5.15124 8.74795 11.8584 4.47091 11.8584 4.47091C11.8584 4.47091 18.5656 8.74795 18.76 8.84515C19.0516 8.74795 20.0237 8.16471 20.4125 7.8731Z" fill="#4B3900"/>
      </svg>
      <img src="https://www.hawaiilife.com/assets/HL-Logo-Text-2020-00fb52702616228c5b5efd7de72d57aca9e8a8d867845cfd39541bec4e9db062.svg" alt="Hawaii Life Real Estate Brokers" />
    </div>
    <div class="spacer20">

    </div>
    <div class="navigation-links-section">
    <h5 @click="toggleSectionOpen('real_estate')" :class="{active:sectionOpen['real_estate']}">
      <span>
        <svg width="22" height="13" viewBox="0 0 22 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 10.8154C9.42877 9.38923 7.90753 7.36035 6.93718 6.06585C6.37669 5.31813 6 4.81543 6 4.81543L0 12.8154H22L13 0.81543L9.25 5.81543L12.1 9.61543L10.5 10.8154ZM11.125 5.81543L14.2 9.91543L12.3333 11.3154H19L13 3.31543L11.125 5.81543ZM8.99962 11.3154C8.20001 10.2504 7.22514 8.95057 6.4161 7.87148L5.99964 7.31591L3 11.3154H8.99962Z" fill="#262626"/>
      </svg>

      Real Estate</span></h5>
    <ul class="navigation-links" v-if="!sectionOpen || sectionOpen['real_estate']">
      <li> <a href="/">All Islands</a></li>
      <li> <a href="/listings/oahu">Oahu</a></li>
      <li> <a href="/listings/kauai">Kauai</a></li>
      <li> <a href="/listings/big-island">Big Island</a></li>
      <li> <a href="/listings/maui">Maui</a></li>
      <li> <a href="/listings/lanai">Lanai</a></li>
      <li> <a href="/listings/molokai">Molokai</a></li>
      <li><a href="https://www.hawaiilife.com/developments">Developments</a></li>
      <li @click="toggleSectionOpen('collections')" class="topic-header">Collections
        <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg" class="abs-top-right" :class="{'no-rotate':!sectionOpen['collections']}">
          <path d="M1.41 8.22531L6 3.64531L10.59 8.22531L12 6.81531L6 0.815308L0 6.81531L1.41 8.22531Z" fill="#262626"/>
        </svg>
      </li>
      <li>
        <ul v-if="sectionOpen['collections']">
        <li><a href="/listings">Luxury</a></li>
        <li><a href="/listings">Short Term Rentals</a></li>
      </ul>
      </li>
      <li class="green"><a href="https://www.hawaiilife.com/video-channel">Watch Video</a></li>
    </ul>
  </div>
  <div class="navigation-links-section">
    <h5 @click="toggleSectionOpen('rentals')" :class="{active:sectionOpen['rentals']}"><span><svg width="22" height="22" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 10.8154H11V16.8154H14V7.81543L8 3.31543L2 7.81543V16.8154H5V10.8154ZM0 18.8154V6.81543L8 0.81543L16 6.81543V18.8154H0Z" fill="#262626"/>
    </svg>
      Rentals</span></h5>
    <ul class="navigation-links" v-if="sectionOpen['rentals']">
      <li> <a href="https://vacations.hawaiilife.com/?utm_content=hawaiife.com">Vacation Rentals</a></li>
      <li> <a href="https://www.hawaiilifelongtermrentals.com/all-vacancies">Long Term Rentals</a></li>
      <li> <a href="https://www.hawaiilife.com/company/long-term-rentals">Property Management</a></li>
    </ul>
  </div>
  <div class="navigation-links-section">
    <h5 @click="toggleSectionOpen('news')" :class="{active:sectionOpen['news']}"><span><svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19 10.8154H22V12.8154H19V10.8154ZM0 10.8154H3V12.8154H0V10.8154ZM12 0.81543V3.81543H10V0.81543H12ZM3.92 3.31543L6.05 5.45543L4.63 6.86543L2.5 4.74543L3.92 3.31543ZM15.95 5.44543L18.07 3.31543L19.5 4.74543L17.37 6.86543L15.95 5.44543ZM11 5.81543C12.5913 5.81543 14.1174 6.44757 15.2426 7.57279C16.3679 8.69801 17 10.2241 17 11.8154C17 14.0354 15.79 15.9754 14 17.0154V18.8154C14 19.0806 13.8946 19.335 13.7071 19.5225C13.5196 19.7101 13.2652 19.8154 13 19.8154H9C8.73478 19.8154 8.48043 19.7101 8.29289 19.5225C8.10536 19.335 8 19.0806 8 18.8154V17.0154C6.21 15.9754 5 14.0354 5 11.8154C5 10.2241 5.63214 8.69801 6.75736 7.57279C7.88258 6.44757 9.4087 5.81543 11 5.81543ZM13 20.8154V21.8154C13 22.0806 12.8946 22.335 12.7071 22.5225C12.5196 22.7101 12.2652 22.8154 12 22.8154H10C9.73478 22.8154 9.48043 22.7101 9.29289 22.5225C9.10536 22.335 9 22.0806 9 21.8154V20.8154H13ZM10 17.8154H12V15.6854C13.73 15.2454 15 13.6754 15 11.8154C15 10.7546 14.5786 9.73715 13.8284 8.987C13.0783 8.23686 12.0609 7.81543 11 7.81543C9.93913 7.81543 8.92172 8.23686 8.17157 8.987C7.42143 9.73715 7 10.7546 7 11.8154C7 13.6754 8.27 15.2454 10 15.6854V17.8154Z" fill="#262626"/>
    </svg>
      News</span></h5>
    <ul class="navigation-links" v-if="sectionOpen['news']">
      <li> <a href="/blog">Hawai'i Life News</a></li>
      <li> <a href="https://vacations.hawaiilife.com/blog">Vacations News</a></li>
    </ul>
  </div>
  <div class="navigation-links-section">
    <h5 @mouseover="openSection('company')" :class="{active:sectionOpen['company']}" @click="toggleSectionOpen('company')"><span><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 14.2054L6.24 16.4754L7.23 12.1954L3.91 9.31543L8.29 8.94543L10 4.90543L11.71 8.94543L16.09 9.31543L12.77 12.1954L13.76 16.4754M20 8.05543L12.81 7.44543L10 0.81543L7.19 7.44543L0 8.05543L5.45 12.7854L3.82 19.8154L10 16.0854L16.18 19.8154L14.54 12.7854L20 8.05543Z" fill="#262626"/>
    </svg>
      Company</span></h5>
    <ul class="navigation-links" v-if="sectionOpen['company']">
      <li v-if="!agent"> <a href="https://www.hawaiilife.com/agents">Find an Agent</a></li>
      <li> <a href="https://www.hawaiilife.com/company/offices/">Find an Office</a></li>
      <li class="topic-header" @click="toggleSectionOpen('about_hawaii_life')"><span>About Hawaii Life</span>
        <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg" class="abs-top-right" :class="{'no-rotate':sectionOpen['about_hawaii_life']}">
          <path d="M1.41 8.22531L6 3.64531L10.59 8.22531L12 6.81531L6 0.815308L0 6.81531L1.41 8.22531Z" fill="#262626"/>
        </svg>
      </li>
      <li>
        <ul class="navigation-links" v-if="sectionOpen['about_hawaii_life']">
          <li><a href="https://www.hawaiilife.com/company/">The Company</a></li>
          <li><a href="https://www.hawaiilife.com/company/team">Leadership And Team</a></li>
          <li><a href="https://www.hawaiilife.com/company/representation">Buyer and Seller Services</a></li>
          <li><a href="https://www.give.hawaiilife.com/">Hawaii Life Charitable Fund</a></li>
<!--          <li><a href="">Customer Experience</a></li>-->
        </ul>
      </li>
      <li class="topic-header" @click="toggleSectionOpen('specializations')"><span>Specializations</span><svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg" class="abs-top-right" :class="{'no-rotate':sectionOpen['specializations']}">
        <path d="M1.41 8.22531L6 3.64531L10.59 8.22531L12 6.81531L6 0.815308L0 6.81531L1.41 8.22531Z" fill="#262626"/>
      </svg>
      </li>
      <li>
        <ul v-if="sectionOpen['specializations']">
          <li><a href="https://www.hawaiilife.com/company/forbes-global-properties/">Forbes Global Properties</a></li>
          <li><a href="https://www.hawaiilife.com/company/military/">Military</a></li>
          <li><a href="https://www.hawaiilife.com/company/global-affiliations">Global Affiliations</a></li>
          <li><a href="https://www.hawaiilife.com/seniors">Seniors</a></li>
          <li><a href="https://www.hawaiilife.com/company/hawaii-life-japan/">Japan and Asia</a></li>
          <li><a href="https://www.hawaiilife.com/developments">Developments</a></li>
          <li><a href="https://www.hl1.com/">HL1 Private Collection</a></li>
          <li><a href="https://www.hawaiilife.com/company/conservation/">Conservation</a></li>
          <li><a href="https://www.hawaiilife.com/company/reo-bank-owned/">REO Bank Owned</a></li>
        </ul>
      </li>
    </ul>
  </div>
    <div class="uppercase-thin-nav border-top user-section-trigger" v-if="user" style="position: relative;">
      <div class="truncated-text-width" style="position:relative;" @click="userNavOpen = !userNavOpen">
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 0.815308C9.06087 0.815308 10.0783 1.23673 10.8284 1.98688C11.5786 2.73703 12 3.75444 12 4.81531C12 5.87617 11.5786 6.89359 10.8284 7.64373C10.0783 8.39388 9.06087 8.81531 8 8.81531C6.93913 8.81531 5.92172 8.39388 5.17157 7.64373C4.42143 6.89359 4 5.87617 4 4.81531C4 3.75444 4.42143 2.73703 5.17157 1.98688C5.92172 1.23673 6.93913 0.815308 8 0.815308ZM8 2.81531C7.46957 2.81531 6.96086 3.02602 6.58579 3.40109C6.21071 3.77617 6 4.28487 6 4.81531C6 5.34574 6.21071 5.85445 6.58579 6.22952C6.96086 6.60459 7.46957 6.81531 8 6.81531C8.53043 6.81531 9.03914 6.60459 9.41421 6.22952C9.78929 5.85445 10 5.34574 10 4.81531C10 4.28487 9.78929 3.77617 9.41421 3.40109C9.03914 3.02602 8.53043 2.81531 8 2.81531ZM8 9.81531C10.67 9.81531 16 11.1453 16 13.8153V16.8153H0V13.8153C0 11.1453 5.33 9.81531 8 9.81531ZM8 11.7153C5.03 11.7153 1.9 13.1753 1.9 13.8153V14.9153H14.1V13.8153C14.1 13.1753 10.97 11.7153 8 11.7153Z" fill="#262626"/>
        </svg>
        <span class="truncated-text-width">{{firstName}} {{lastName}}</span>
      </div>
      <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg" class="abs-top-right rotate-180" :class="{'no-rotate':userNavOpen}">
        <path d="M1.41 8.22531L6 3.64531L10.59 8.22531L12 6.81531L6 0.815308L0 6.81531L1.41 8.22531Z" fill="#262626"/>
      </svg>

    </div>


    <div class="navigation-links-section" v-if="userNavOpen && user">
      <ul class="navigation-links for-user">
        <li><DataBrick></DataBrick>
          <a @click="doShowAccountPanel">Overview</a></li>
        <li><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.1 16.3653L10 16.4653L9.89 16.3653C5.14 12.0553 2 9.20531 2 6.31531C2 4.31531 3.5 2.81531 5.5 2.81531C7.04 2.81531 8.54 3.81531 9.07 5.17531H10.93C11.46 3.81531 12.96 2.81531 14.5 2.81531C16.5 2.81531 18 4.31531 18 6.31531C18 9.20531 14.86 12.0553 10.1 16.3653ZM14.5 0.815308C12.76 0.815308 11.09 1.62531 10 2.89531C8.91 1.62531 7.24 0.815308 5.5 0.815308C2.42 0.815308 0 3.22531 0 6.31531C0 10.0853 3.4 13.1753 8.55 17.8453L10 19.1653L11.45 17.8453C16.6 13.1753 20 10.0853 20 6.31531C20 3.22531 17.58 0.815308 14.5 0.815308Z" fill="#262626"/>
        </svg>
          <a @click.stop="doShowFavoritesPanel">Favorites</a></li>
        <li><svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7 19.8153H11C11 20.9153 10.1 21.8153 9 21.8153C7.9 21.8153 7 20.9153 7 19.8153ZM18 17.8153V18.8153H0V17.8153L2 15.8153V9.81531C2 6.71531 4 4.01531 7 3.11531V2.81531C7 1.71531 7.9 0.815308 9 0.815308C10.1 0.815308 11 1.71531 11 2.81531V3.11531C14 4.01531 16 6.71531 16 9.81531V15.8153L18 17.8153ZM14 9.81531C14 7.01531 11.8 4.81531 9 4.81531C6.2 4.81531 4 7.01531 4 9.81531V16.8153H14V9.81531Z" fill="#262626"/>
        </svg>
          <a @click.stop="doShowSavedSearchesPanel">Saved Searches</a></li>
        <li>
          <a @click.stop="doShowProfilePanel"><ProfileGearIcon></ProfileGearIcon> Profile</a></li>
        <li v-if="showAdmin">
          <ShieldIcon></ShieldIcon><a @click="goAdmin"> Admin</a>
        </li>
        <li v-if="showAgentAdmin">
          <ShieldIcon></ShieldIcon>
          <a @click="goAgentAdmin">Your Admin</a>
        </li>
      </ul>
    </div>
  <div v-if="!user" class="sign-in-wrapper">
    <a @click="doShowSignIn" class="hl-btn wide">Sign In</a>
  </div>
  <div v-if="!user" class="sign-up-wrapper">
    <a @click.stop="doShowSignUp" class="hl-btn grey"> Sign Up</a>
  </div>
    <div v-if="user">
      <a @click.stop="logout" class="hl-btn grey log-out"><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.08 13.4053L13.67 10.8153H4V8.81531H13.67L11.08 6.22531L12.5 4.81531L17.5 9.81531L12.5 14.8153L11.08 13.4053ZM16 0.815308C16.5304 0.815308 17.0391 1.02602 17.4142 1.40109C17.7893 1.77617 18 2.28487 18 2.81531V7.48531L16 5.48531V2.81531H2V16.8153H16V14.1453L18 12.1453V16.8153C18 17.3457 17.7893 17.8544 17.4142 18.2295C17.0391 18.6046 16.5304 18.8153 16 18.8153H2C0.89 18.8153 0 17.9153 0 16.8153V2.81531C0 1.70531 0.89 0.815308 2 0.815308H16Z" fill="#737373"/>
      </svg>
        Log Out</a>
    </div>
    <span class="light-grey-text small" v-if="loggedIn">{{email}}</span>


    <div v-if="showCallTextMenu" style="margin-top:auto;" class="call-text-menu pinned-bottom">
      <a class="hl-btn grey inline" href="tel:800-370-3848"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17 12.5C15.8 12.5 14.5 12.3 13.4 11.9C13.3 11.9 13.2 11.9 13.1 11.9C12.8 11.9 12.6 12 12.4 12.2L10.2 14.4C7.4 12.9 5 10.6 3.6 7.8L5.8 5.6C6.1 5.3 6.2 4.9 6 4.6C5.7 3.5 5.5 2.2 5.5 1C5.5 0.5 5 0 4.5 0H1C0.5 0 0 0.5 0 1C0 10.4 7.6 18 17 18C17.5 18 18 17.5 18 17V13.5C18 13 17.5 12.5 17 12.5ZM2 2H3.5C3.6 2.9 3.8 3.8 4 4.6L2.8 5.8C2.4 4.6 2.1 3.3 2 2ZM16 16C14.7 15.9 13.4 15.6 12.2 15.2L13.4 14C14.2 14.2 15.1 14.4 16 14.4V16Z" fill="#737373"/>
      </svg>
        Call Us</a>
      <a class="hl-btn grey inline" href="sms:800-370-3848"><svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 0C4.5 0 0 3.58 0 8C0.05 10.15 1.06 12.17 2.75 13.5C2.75 14.1 2.33 15.67 0 18C2.37 17.89 4.64 17 6.47 15.5C7.61 15.83 8.81 16 10 16C15.5 16 20 12.42 20 8C20 3.58 15.5 0 10 0ZM10 14C5.58 14 2 11.31 2 8C2 4.69 5.58 2 10 2C14.42 2 18 4.69 18 8C18 11.31 14.42 14 10 14ZM15 9V7H13V9H15ZM11 9V7H9V9H11ZM7 9V7H5V9H7Z" fill="#262626"/>
      </svg>
        Text Us</a>
    </div>
    <div class="pinned-bottom" style="margin-top:auto;" v-if="!showCallTextMenu">
      <div class="hl-btn grey call-or-text-us-btn" @click.stop="showCallTextMenu = true">Call or Text us</div>
      <p>800-370-3848</p>
    </div>

  </div>
</div>
</template>

<script>
import CloseX from './close-x';
import ArrowRight from './ArrowRight';
import {mapGetters} from 'vuex';
import DataBrick from "./SVG/Icons/DataBrick.vue";
import ProfileGearIcon from "./SVG/Icons/ProfileGearIcon.vue";
import ShieldIcon from "./SVG/Icons/ShieldIcon.vue";
export default {
  name: "TopNav",
  components:{ShieldIcon, ProfileGearIcon, DataBrick, CloseX,ArrowRight},
  props:['loggedIn','logout','toggleNav','external','hideUserMenu'],
  data(){
    return {
      sectionOpen:{
        real_estate:false,
        news:false,
        company:false,
        rentals:false,
        collections:false,
        about_hawaii_life:false,
        specializations:false,
      },
      userNavOpen:false,
      showCallTextMenu:false,
    }
  },
  computed:{
    ...mapGetters({'slugAgent':'ui/slugAgent','mapComponentPresent':'ui/mapComponentPresent'}),
    agent(){
      let agent = null;
      if(window.HL_NAV_USER && window.HL_NAV_USER.advisor){
        agent = window.HL_NAV_USER.advisor;
        return agent;
      }
      if(this.$store.state.user && this.$store.state.user.agent){
        return this.$store.state.user.agent;
      }
      if(this.slugAgent){
        return this.slugAgent;
      }
    },
    user(){
      if(this.external){
        if(window.HL_NAV_USER_DATA && window.HL_NAV_USER_DATA.user) return window.HL_NAV_USER_DATA.user;
        return null;
      }
      if(this.$store.state.user) {

        return this.$store.state.user.user;
      }
    },
    email(){
      let {user} = this.$store.state;
      if(this.user) user = this.user;
      if(user && user.email) return user.email
    },
    firstName(){
      let {user} = this.$store.state;
      if(!user && this.user){
        user = this.user;
      }
      if(user && user.first_name) return user.first_name;
      const n = user.user;
      if(n){
        return n.first_name;
      }
    },
    lastName(){
      let {user} = this.$store.state;
      if(!user && this.user){
        user = this.user;
      }
      if(user && user.last_name) return user.last_name;
      const n = user.user;
      if(n){
        return n.last_name;
      }
    },
    showAdmin(){
      let {user} = this.$store.state;
      let roles = [];
      if(!user && this.user){
        user = this.user;
      }
      roles = user.roles;
      if(roles && roles.indexOf('super_admin') > -1){
        return true;
      }
      return false;
    },
    showAgentAdmin(){
      let {user} = this.$store.state;
      let roles = [];
      if(!user && this.user){
        user = this.user;
      }
      roles = user.roles;
      if(roles && roles.indexOf('super_admin') > -1){
        return true;
      }
      return false;
    }
  },
  methods:{
    goHome(){
      window.location = '/';
    },
    goAdmin(){
      window.location = '/admin';
    },
    goAgentAdmin(){
      window.location = '/agent_tools';
    },
    doShowAccountPanel(){
      if(!this.mapComponentPresent || this.external){
        window.location = '/account/overview';
        return;
      }
      this.$store.dispatch('ui/doOpenAccountPanel',true);
      this.closeNav();
    },
    doShowFavoritesPanel(){
      if(!this.mapComponentPresent || this.external){
        window.location = '/account/favorites';
        return;
      }
      this.$store.dispatch('ui/doOpenFavoritesPanel',true);
      this.closeNav();
    },
    doShowProfilePanel(){
      if(!this.mapComponentPresent || this.external){
        window.location = '/account/settings';
        return;
      }
      this.$store.dispatch('ui/doOpenProfile',true);
      this.closeNav();
      //
    },
    doShowSavedSearchesPanel() {
      if(!this.mapComponentPresent){
        window.location = '/account/saved-searches';
        return;
      }
      this.$store.dispatch('ui/doOpenSavedSearchesPanel', true);
      this.closeNav();
    },
    doShowSignIn(){
      if(this.external){
        window.location = '/listings?show_sign_in_modal=1';
        return;
      }
      this.$store.commit('ui/setShowLogin',true);
    },
    doShowSignUp(){
      if(this.external){
        window.location = '/listings?show_sign_in_modal=1';
        return;
      }
      this.$store.commit('ui/setShowRegister',true);
    },
    openSection(section){
      this.sectionOpen[section] = true;
    },
    closeNav(){
      if(!this.external) this.$store.commit('ui/doHideNav');
      if(this.hideUserMenu) this.hideUserMenu();
    },
    toggleSectionOpen(section){
     this.sectionOpen[section] = !this.sectionOpen[section];
      }
  }
}
</script>

<style lang="scss" scoped>

@import '../assets/scss/variables/default';
@import '../assets/scss/variables/media';
@import '../assets/scss/TopNav.scss';
</style>

<template>
<div class="universal-search" :class="{external: external,relative: relative,hidden:hidden}" @click.stop="() => {}">
  <input type="text" ref="searchOnDesktop" autocomplete="off" class="universal-search-input-text" :class="{higher:focusedSearch}" @click="registerAndFocusSearch" v-model="searchText" :placeholder="searchPlaceholderText" />
  <a class="higher">
    <svg @click.stop="registerCurrentSearchToHistory" v-if="searchText && searchText.length" width="14" height="14" class="clear-input register-clear-svg" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="#9E9E9E"/>
    </svg>
    <svg class="higher" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.5 0C8.22391 0 9.87721 0.684819 11.0962 1.90381C12.3152 3.12279 13 4.77609 13 6.5C13 8.11 12.41 9.59 11.44 10.73L11.71 11H12.5L17.5 16L16 17.5L11 12.5V11.71L10.73 11.44C9.59 12.41 8.11 13 6.5 13C4.77609 13 3.12279 12.3152 1.90381 11.0962C0.684819 9.87721 0 8.22391 0 6.5C0 4.77609 0.684819 3.12279 1.90381 1.90381C3.12279 0.684819 4.77609 0 6.5 0ZM6.5 2C4 2 2 4 2 6.5C2 9 4 11 6.5 11C9 11 11 9 11 6.5C11 4 9 2 6.5 2Z" fill="#FAFAFA"/>
  </svg>
  </a>
  <div v-if="searchText && searchText.length || searchHistory && searchHistory.length && focusedSearch" class="universal-search-results-on-desktop" :style="desktopSearchStyle">
    <SearchResultsList
    :get-category-for-blog-post="getCategoryForBlogPost"
    :get-date-for-blog-post="getDateForBlogPost"
    :truncate="truncate"
    :get-breadcrumb-for-listing="getBreadcrumbForListing"
    :translate-listing-label="translateListingLabel"
    :get-c-d-n-u-r-l="getCDNURL"
    :register-search-and-navigate="registerSearchAndNavigate"
    :go-to-agent-page="goToAgentPage"
    :blog-results="blogResults"
    :agent-search-results="agentSearchResults"
    :listing-results="listingResults"
    :loading-agents="loadingAgents"
    :search-history="searchHistory"
    :remove-search-at-index="removeSearchAtIndex"
    :populate-search-text-and-search="populateSearchTextAndSearch"
    :activate-search="activateSearch"
    :saved-searches="savedSearches"
    :region-results="regionResults"
    :fetch-or-show-summary-for-region="fetchOrShowSummaryForRegion"
    :get-region-type="getRegionType"
    :get-small-label="getSmallLabel"
    :translate-label="translateLabel"
    :getIconSrc="getIconSrc"
    :navigate-to-blog-post="navigateToBlogPost"
    :loading-listing-results="loadingListingResults"
    :show-previous-search="showPreviousSearch"
    :hidden-result-count="hiddenResultCount"
    :search-text="searchText"
    :highlight="highlight"
    :external="external"
    ></SearchResultsList>
    <div class="pr">
    <div class="bottom-overlay"></div>
      </div>
  </div>

  <div v-if="focusedSearch" class="backdrop top-one">
    <div class="inner-top-one">
      <svg class="back" @click.stop="closeAndResetSearch" width="12" height="20" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.41 10.58L2.83 6L7.41 1.41L6 0L0 6L6 12L7.41 10.58Z" fill="#737373"/>
      </svg>
      <div class="universal-search">
      <input class="universal-search-input-text" autocomplete="off" ref="searchInBackdrop" type="text" :placeholder="searchPlaceholderText" v-model="searchText" @input="keyupSearch" />
        <svg @click="registerCurrentSearchToHistory" width="14" height="14" class="clear-input" v-if="searchText && searchText.length" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="#9E9E9E"/>
        </svg>
      <a><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.5 0C8.22391 0 9.87721 0.684819 11.0962 1.90381C12.3152 3.12279 13 4.77609 13 6.5C13 8.11 12.41 9.59 11.44 10.73L11.71 11H12.5L17.5 16L16 17.5L11 12.5V11.71L10.73 11.44C9.59 12.41 8.11 13 6.5 13C4.77609 13 3.12279 12.3152 1.90381 11.0962C0.684819 9.87721 0 8.22391 0 6.5C0 4.77609 0.684819 3.12279 1.90381 1.90381C3.12279 0.684819 4.77609 0 6.5 0ZM6.5 2C4 2 2 4 2 6.5C2 9 4 11 6.5 11C9 11 11 9 11 6.5C11 4 9 2 6.5 2Z" fill="#FAFAFA"/>
      </svg>
      </a>
    </div>
    </div>
    <div class="universal-search-results-on-mobile">
    <SearchResultsList
        :get-category-for-blog-post="getCategoryForBlogPost"
        :get-date-for-blog-post="getDateForBlogPost"
        :truncate="truncate"
        :get-breadcrumb-for-listing="getBreadcrumbForListing"
        :translate-listing-label="translateListingLabel"
        :get-c-d-n-u-r-l="getCDNURL"
        :register-search-and-navigate="registerSearchAndNavigate"
        :go-to-agent-page="goToAgentPage"
        :blog-results="blogResults"
        :agent-search-results="agentSearchResults"
        :listing-results="listingResults"
        :loading-agents="loadingAgents"
        :search-history="searchHistory"
        :remove-search-at-index="removeSearchAtIndex"
        :populate-search-text-and-search="populateSearchTextAndSearch"
        :activate-search="activateSearch"
        :saved-searches="savedSearches"
        :region-results="regionResults"
        :search-text="searchText"
        :fetch-or-show-summary-for-region="fetchOrShowSummaryForRegion"
        :get-region-type="getRegionType"
        :get-small-label="getSmallLabel"
        :translate-label="translateLabel"
        :getIconSrc="getIconSrc"
        :navigate-to-blog-post="navigateToBlogPost"
        :loading-listing-results="loadingListingResults"
        :show-previous-search="showPreviousSearch"
        :hidden-result-count="hiddenResultCount"
        :all-results-hidden="allResultsHidden"
        :highlight="highlight"
        :external="external"
        :agent-or-slug-agent="agentOrSlugAgent"
    ></SearchResultsList>
      <div class="pr">
        <div class="bottom-overlay"></div>
      </div>
    </div>
  </div>
  <div v-if="focusedSearch" @click="focusedSearch = false;" class="overlay-bg-search-desktop"></div>


</div>
</template>

<script>
import axios from 'axios';
import debounce from 'debounce';
import getCDNURL from "../helpers/getCDNURL";
import smallDate from '../helpers/small-date';
import SearchResultsList from "./SearchResultsList.vue";
import {highlight} from "elastic-builder";

let listing_base = 'https://www.hawaiilife.com/listings';

export default {
  name: "UniversalSearchComponent",
  components: {
    SearchResultsList: SearchResultsList
  },
  props: {
    external: {
      type: Boolean,
      default: false,
    },
    hidden:{
      type: Boolean,
      default: false,
    },
    relative:{
      type:Boolean,
      default:false
    },
    agentOrSlugAgent:{
      type: Object,
      default: null,
    }
  },
  watch: {
    searchText: function (text, oldtext) {
      if (this.searchText && this.searchText.length > 2) {
        this.runSearch();
      }
    }
  },
  data() {
    return {
      focusedSearch: false,
      regionResults: [],
      searchText: null,
      flattenedRegions:null,
      loadingAgents: false,
      loadingListingResults: false,
      listingResults: [],
      agentSearchResults: [],
      searchHistory: [],
      blogResults: [],
      aggregates: null,
      xClicked:false,
      regionNameHandles:{},
    }
  },
  async mounted() {
    try {
      const result = this.$cookies.get('searches');
      if (result) {
        let r = JSON.parse(result);
        r = r.filter(item => {
          return item && item !== '';
        })
        this.searchHistory = r;
      }
    }catch(e){

    }
    if(this.$eventBus){
    this.$eventBus.$on('close-dropdowns', (results) => {
      if(this.searchText){
        this.searchText = null;
      }
      this.focusedSearch = false;
    });
    this.$eventBus.$on('close-search', (results) => {
      if(this.searchText){
        this.searchText = null;
      }
      this.focusedSearch = false;
    });
    }
  },
  computed: {
    hiddenResultCount(){
      const r = this.searchHistory.length;
      let hiddenCount = 0;
      if(this.searchText){
        for(var search of this.searchHistory){
          if(!this.showPreviousSearch(search)){
            hiddenCount++;
          }
        }
      }
      return hiddenCount;
    },
    desktopSearchStyle(){
      let w = this.$refs.searchOnDesktop;
      if(!w) return {};
      let cw = w.clientWidth;
      if(this.external && this.relative){
        return {width: cw + 27 + 'px'};
      }
      return {width:w.clientWidth + 'px'};
    },
    allRegions(){
      if(this.$store.state.listings.allRegions){
        return this.$store.state.listings.allRegions;
      }
      //return this.flattenedRegions;
    },
    savedSearches() {
      if (!this.external) return this.$store.state.listings.savedSearches;
      return this.$store.state.external.listings.savedSearches;
    },
    searchPlaceholderText() {
      return 'Search and find Real Estate in Hawaii'
    },
    baseUrl() {
      if (this.external) {
        return "https://www.hawaiilife.com";
      }
      return '';
    },
    baseAgentUrlForListings() {
      if(this.agentOrSlugAgent){

        return `${this.agentOrSlugAgent.root_path}`;
      }
    }
  },

  methods: {
    unFocusSearch(){
      this.focusedSearch = false;
    },
    allResultsHidden(){
      const r = this.searchHistory.length;
      let hidden = 0;
      if(this.searchText){
        for(var search of this.searchHistory){
          if(!this.showPreviousSearch(search)){
            hidden++;
          }
        }
      }
      if(hidden === r){
        return true;
      }
    },
    highlight,
    showPreviousSearch(search) {
      if(this.searchText && this.searchText.length > 0){
       //if we have listings text, the listings needs to contain the string otherwise we return false
        return search.toLowerCase().includes(this.searchText.toLowerCase());
      }
      return true;
    },
    registerAndFocusSearch() {
      this.focusedSearch = true;
      this.runFlattenRegions();
      this.$nextTick(() => {
        if (this.$refs.searchInBackdrop) {
          this.$refs.searchInBackdrop.focus();
        }
      })
      if(this.$eventBus){
        this.$eventBus.$emit('close-dropdowns', {sender: 'universal-search'});
        this.$eventBus.$emit('search-focused', {sender: 'universal-search'});
      }

    },
    async fetchAndRunSearch(){
      if(!this.aggregates || !this.aggregates.hl_regions){
        await this.runFetchRegionNameHandles();
        this.runRegionSearch();
        this.debounceListingSearch();
        this.debounceBlogSearch();

      }
    },
    navigateToBlogPost(post){
      //open post in new window
      window.open(post.link, '_blank');

    },
    async runFlattenRegions(){
      if(!this.flattenedRegions){
        const {data} = await axios.get(this.baseUrl + '/api/v3/search/regions/paths?wc=1');
        this.flattenedRegions = data;
      }
    },

    async runFetchRegionNameHandles(){
        if(Object.keys(this.regionNameHandles).length > 0) return;
        const result = await axios.get(this.baseUrl + '/api/v3/search/aggregates/');

        this.aggregates = result.data;
        const {data} = await axios.get(this.baseUrl + '/api/v3/search/regions');
        let rn = {};
        let hn = {};
        for(var section in data){
          const options = data[section];
          for(var name in options){
            rn[options[name]] = name.trim();
            rn[name.trim()] = options[name];
          }
        }
        this.regionNameHandles = rn;
    },
    async runRegionSearch(){
      await this.runFetchRegionNameHandles();
      await this.runFlattenRegions();
      if(!this.searchText || this.searchText.length === 0) return;



      let r = JSON.parse(JSON.stringify(this.flattenedRegions));
      if(!r){
        return;
      }
      r = r.filter(region => !region.toLowerCase().includes('area of service'));
      const results = r.filter(region => region.toLowerCase().includes(this.searchText.toLowerCase()));
      this.regionResults = results.map(item => {
        return {label: item, summary: null};
      });
      this.regionResults.sort((a, b) => {
        return a.label.length - b.label.length;
      });


    },
    getIconSrc(result) {
      if(!result.photos || result.photos.length === 0) return '';
      //get rid of empty items in the array
      let ph = result.photos;
      //remove any that are null
      let r = [];
      ph.forEach(item => {
        if(item){
          r.push(item);
        }
      });
      if(r.length === 0) return '';
      let str = `https://d1gt3v5rrahuun.cloudfront.net${r[0].thumb}`;
      str = str.replace('rets-mls/', '');
      return str;
    },
    smallDate,
    getCDNURL,
    goToAgentPage(result) {
      window.open(result.direct_path, '_blank');
    },
    activateSearch(search) {
      this.$store.dispatch('listings/setActiveSavedSearch', search);
      this.focusedSearch = false;
    },
    getCategoryForBlogPost(post) {
      //get the category from the json
      const cat = post._embedded['wp:term'] && post._embedded[0] && post._embedded[0][0] ? post._embedded[0][0] : {name: 'Uncategorized'};
      return ''
      return cat.name;
    },
    getDateForBlogPost(date) {
      const d = new Date(date);
      return smallDate(d, 'MMMM, yyyy');
    },
    getBreadcrumbForListing(listing) {
      let p = [];
      if (listing.island) p.push(listing.island);
      if (listing.district) p.push(listing.district);
      if (listing.town) p.push(listing.town);
      if (listing.community) p.push(listing.community);
      return p.join(' > ');
    },
    truncate(text, amount, addEllipsis = true) {
      let el = '';
      if (addEllipsis) {
        el = '...';
      }
      if (text.length > amount) {
        return text.substring(0, amount) + el;
      }
      return text;
    },
    addRandomToCookie() {
      const t = 'search' + (Math.random() * 10000).toString();
      this.$cookies.set('searches', JSON.stringify([t, ...JSON.parse(this.$cookies.get('searches') || '[]')]));
    },
    getSmallLabel(label) {
      label = label.replace('Island::', '');
      label = label.replace('District::', '');
      label = label.replace('Town::', '');
      label = label.replace('Community::', '');
      label = label.replace('Resort::','');
      label = label.replace('Subdivision::','');
      label = label.replace('Neighborhood::','');
      label = label.replace('Condo::','');
      label = label.replace('Building Name::','');
      return label;
    },
    debounceListingSearch: debounce(async function () {
      this.loadingListingResults = true;
      const results = await axios.get(this.baseUrl + '/api/v3/search/query', {params: {s: this.searchText}});
      this.listingResults = [];
      this.listingResults = results.data;
      this.loadingListingResults = false;
      this.$forceUpdate();
    }, 500),
    debounceBlogSearch: debounce(async function () {
      this.loadingBlogResults = true;
      this.$forceUpdate();
      const results = await axios.get('https://about.hawaiilife.com/wp-json/wp/v2/posts?_embed', {params: {search: this.searchText}});
      this.blogResults = results.data;
      this.loadingBlogResults = false;
    }, 500),
    populateSearchTextAndSearch(search) {
      this.searchText = search;
      this.runSearch();
    },

    async fetchOrShowSummaryForRegion(region, idx) {
      if (this.regionResults[idx].summary || this.external) {
        this.registerSearchAndNavigate('area', {item: region});
        return;
      }
      let r = JSON.parse(JSON.stringify(this.regionResults));
      r[idx].summary = {loading: true};
      this.regionResults = r;
      this.$forceUpdate();
      const result = await this.$store.dispatch('listings/getSummaryForRegion', region);
      const {property_type_averages} = result;
      let data = [];
      for (var opt of property_type_averages.options) {
        if (opt.label === 'Commercial') continue;
        data.push({
          label: opt.label,
          value: opt.value,
          count: opt.item_count,
          average: opt.avg
        });
      }
      r[idx].summary['breakdown'] = data;
      r[idx].summary.loading = false;
      this.$forceUpdate();
      return data;
    },
    resetSearch() {
      this.listingResults = [];
      this.agentSearchResults = [];
      this.regionResults = [];
      this.blogResults = [];
      this.searchText = null;
    },
    closeAndResetSearch() {
      this.resetSearch();
      this.focusedSearch = false;
    },
    translateListingLabel(label) {
      if (!label) {
        return;
      }
      const highlight = (needle, haystack) =>

        haystack.replace(
          new RegExp(needle, 'gi'),
          (str) => `<span class="hl-highlighted">${str}</span>`
        );
      return highlight(this.searchText, label);
    },
    clearInput() {
      this.searchText = null;
      this.xClicked = true;
      this.focusedSearch = false;

    },
    removeSearchAtIndex(idx) {
      this.searchHistory.splice(idx, 1);
      this.$forceUpdate();
      this.serializeSearchHistoryToCookie();
    },
    serializeSearchHistoryToCookie() {
      try {
        this.$cookies.set('searches', JSON.stringify(this.searchHistory));
      }catch(e){
      }
    },
    translateLabel(label) {
      if (!label) {
        return;
      }
      const f = label.split(' > ');
      const l = f.length;
      //get last label
      const last = f[l - 1];
      if (!last.includes('::')) {
        return last;
      }

      const ff = last.split('::')[1].trim();
      const highlight = (needle, haystack) =>
        haystack.replace(
          new RegExp(needle, 'gi'),
          (str) => `<strong>${str}</strong>`
        );
      return highlight(this.searchText, ff);
    },
    registerCurrentSearchToHistory() {
      const t = this.searchText;
      if (!this.searchHistory.includes(t) && t) {
        this.searchHistory = [t, ...this.searchHistory];
      }
      this.serializeSearchHistoryToCookie();
      this.searchText = null;
    },
    async registerSearchAndNavigate(action, data) {
      if(this.external){
        if(action === 'listing'){
          window.location = this.baseUrl + '/listings/' + data.slug;
          return;
        }

        let parts = [];
        const p = data.item.split(' > ');
        const [island,district,town,community] = p;
        if(island){
          let islandText = island.split('::')[1].trim();
          parts.push(this.regionNameHandles[islandText]);
        }
        if(district){
          parts.push(this.regionNameHandles[district.split('::')[1].trim()]);
        }
        if(town){
          parts.push(this.regionNameHandles[town.split('::')[1].trim()]);
        }
        if(community){
          parts.push(this.regionNameHandles[community.split('::')[1].trim()]);
        }
        let u = parts.join('/');
        if(this.agentOrSlugAgent){
          window.location = this.baseAgentUrlForListings + '/listings/' + u;
        }
        window.location = this.baseAgentUrlForListings + '/listings/' + u;
        return;
      }


      this.registerCurrentSearchToHistory();
      if (action === 'area') {
        await this.setSingularRegionSearch(data.item);
        if (data.type && data.type.label) {
          this.$store.commit('listings/setTypesFilter', [data.type.label]);
        }
        await this.$store.dispatch('listings/doSearch');
        if (window.HL_UPDATE_MAP_MARKERS) window.HL_UPDATE_MAP_MARKERS();
        this.$store.dispatch('listings/updateUrl');
      }
      if (action === 'listing') {
        data = {...data, replaceState: true};

        await this.$store.dispatch('listings/setActiveListing', data);
      }

      this.searchText = null;
      this.listingResults = [];
      this.blogResults = [];
      this.agentSearchResults = [];
      this.focusedSearch = false;
      this.$forceUpdate();
    },


    getRegionType(label) {
      const f = label.split(' > ');
      const l = f.length;
      //get last label
      const last = f[l - 1];
      if(label.includes('Island::Oahu') && l > 3){
        return 'Condo/Townhouse';
      }


      return last.split('::')[0].trim();
    },
    focus() {
      this.focusedSearch = true;
      if(this.external && !this.aggregates.hl_regions){
        this.runFetchRegionNameHandles();
      }
      if(this.$eventBus) this.$eventBus.$emit('search-focused');
    },
    async setSingularRegionSearch(region, type = null) {
      if(region.includes("Resort::")){
        region = region.replace("Resort::","Community::");
      }
      if(region.includes("Subdivision::")){
        region = region.replace("Subdivision::","Community::");
      }
      if(region.includes("Neighborhood::")){
        region = region.replace("Neighborhood::","Community::");
      }
      if(region.includes('Condo::')){
        region = region.replace('Condo::','Community::');
      }
      this.$store.commit('listings/setHLRegions', [region]);
      this.$store.commit('listings/setFiltersChanged', true);
      this.$store.dispatch('listings/setContent');
      //add fetch content;
    },
    async runSearchUpdateMarkers() {
      await this.$store.dispatch('listings/doSearch');
      if (window.HL_UPDATE_MAP_MARKERS) window.HL_UPDATE_MAP_MARKERS();
    },
    async runSearch() {
      if (this.searchText && this.searchText.length > 3) {
        this.debounceListingSearch();
        this.debounceBlogSearch();
        this.loadingAgents = true;
        const {data} = await axios.get(this.baseUrl + '/api/v3/search/agents', {
          params: {
            search: this.searchText.trim()
          }
        });
        this.agentSearchResults = data;
        this.loadingAgents = false;
      } else {
        this.agentSearchResults = [];
        this.loadingAgents = false;
      }
      this.runRegionSearch();

      setTimeout(() => {
        this.debounceListingSearch();
      }, 200);
      return true;
    },
    async keyupSearch(e) {
      this.searchText = e.target.value;
      await this.runSearch();
      setTimeout(() => {
        this.runSearch()
      }, 500);
    }

  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables/default.scss";
@import '../assets/scss/mixins/box-shadow.scss';
@import '../assets/scss/variables/media.scss';
.universal-search{
  font-size:16px;
  &.external{
    width:100%;
    .ds{
      width:105%;
    }
    &.relative{
      input[type="text"]{
        padding-top:10px !important;
        padding-bottom:10px !important;
        height:52px;
        background-color: white;
      }
      height:52px;
      a{
        background-color:$color-hl-blue4;
        display:flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-grow:0;
        width:52px;
        height:52px;
      }

      .universal-search-results-on-desktop{
        position:absolute;
        color:#333333;
        top: 50px;
        width:100%;
      }
    }
  }
  @include hl-box-shadow-sm;
  height:40px;
  display:flex;
  width:100%;
  position:relative;
  @media(min-width: $media-mobile){
  }
  flex-direction: row;
  input[type='text']{
    outline:none;
    padding: 4px 12px;
    border:1px #f5f5f5 solid;
    flex-grow:1;
    font-size:16px;
    &:hover, &:active, &:focus{
      background-color: white !important;
    }
  }
  a{
    background-color:$color-hl-blue4;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-grow:0;
    width:40px;
  }
  .see-all-blog-posts{
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .see-all-blog-posts a{
    width:auto;
    justify-self: flex-start;

  }



  .backdrop{
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color:rgba(230,230,230,0.2);
    height:100vh;
    width:100vw;
    z-index:5003;
    backdrop-filter: blur(3px);
    @media(min-width: $media-mobile){
      backdrop-filter: revert;
      display:none;
    }
  }
}

.inner-top-one{
  background-color: white;
  @media(min-width: $media-mobile){
    display:none;
  }
  @media(max-width: $media-mobile){
    img.logo{
      max-width:32px;
    }
  }
  display:flex;
  flex-direction:row;
  padding:10px;
  padding-top:25px;
  align-items: center;
  align-items: center;
  padding-bottom:20px;
  svg.back{
    margin:15px;
    margin-right:30px;
    margin-left:15px;
  }
}

.results-list-item{
  padding:10px;
  display:flex;
  align-items: center;
  flex-wrap: nowrap;
  .icon{
    min-width:80px;
    display:flex;
    justify-content: center;
  }
  &.listing{
    .summary{
      padding-left:5px;
    }
  }

  &.area{
    .summary{
      padding-left:5px;
    }
  }
  .summary{
    font-family:HL-Whitney-Book,sans-serif;
    display:flex;
    flex-direction:column;
    strong{
      font-weight:700;
      font-family:HL-Whitney-Bold,sans-serif;
    }
  }
  p.small-label{
    font-size:12px;
    color:#737373;
    font-family: HL-Whitney-Book,sans-serif;
    margin-bottom:0;
    padding-bottom:0;
    &.mls{
      color:#333333;
    }
  }

}

#dots .dot1{
  animation: load 1s infinite;
}

#dots .dot2{
  animation: load 1s infinite;
  animation-delay: 0.2s;
}

#dots .dot3{
  animation: load 1s infinite;
  animation-delay: 0.4s;
}

@keyframes load{
  0%{
    opacity: 0;
  }
  50%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}

.summary-under{
  width:100%;
  .loading-svg{
    margin-left:40px;
  }
  .breakdown-item{
    padding:10px;
    font-family: HL-Whitney-Book,sans-serif;
    display:flex;
    flex-direction: row;
    align-items: center;
    svg{
      margin-left:40px;
      margin-right:20px;
    }
    .item-count{
      font-size:12px;
      color:#737373;
    }
    .breakdown-type-dwelling{
      font-size:16px;
      margin-left:5px;
    }

  }
}

.agent-image{
  @include hl-box-shadow-md;
  border-radius:50px;
  height:50px;
  width:50px;
}
.agent-name{
  font-size:18px;
  padding-left:10px;
}

.need-help{
  font-size:12px;
  color:#737373;
  font-family: HL-Whitney-Book,sans-serif;
  padding-left:20px;
  background-color: white;
}

.results-list{
  > .loading-svg{
    margin-left:20px;
  }
}

.listing-image{
  max-height:60px;
  max-width:60px;
  margin-right:0px;
  border-radius:30px;
}
.clear-input{
  position:absolute;
  right:40px;
  top:15px;
}
.ml-auto{
  margin-left:auto;
  padding-right:10px;
}
.ds{
  @include hl-box-shadow-sm;
  height:calc(85vh);
  overflow-y: scroll;
  border-bottom:1px #737373 solid;
  position:relative;
}

.small-grey-text{
  font-family: HL-Whitney-Book,sans-serif;
  font-size:12px;
  color:#737373;
  clear:both;
}
.see-all-blog-posts{
  padding:0;
  padding-left:10px;
  a{
    background-color: rgba(255,255,255,0);
    color:black;
    margin-bottom:10px;
  }
}

@media(min-width: $media-mobile){
  .universal-search{
    width:100%;
    position:relative;
    svg{
      top:15px;
      right:60px;
    }
    &.relative{
      svg{
        top:18px;
        right:65px;
      }
    }
  }

  .ds{
    width:100%;
    display:flex;
    padding-left:10px;
    padding-right:10px;
    background-color: white;
    flex-direction:row;
    justify-content: stretch;
    height:auto;
    box-sizing:border-box;
    max-height:70vh;
    overflow-y: scroll;
    position:relative;
    .results-list{
      flex-grow:1;
    }
  }
  .section-wrapper{
    width:100%;
  }
  .results-list-item.area{
    flex-wrap: wrap;
    .summary-under{
      padding-left:40px;
    }
    &:hover{
      cursor:pointer;
    }

  }
  .see-all-blog-posts a{
    margin-bottom:0px;
    padding-bottom:0;
  }
  .logo{
    max-width:40px;
    margin-right:20px;

  }
  .logo-full-screen-modal-text{
    max-width:150px;
  }

  .universal-search-results-on-desktop{
    position:fixed;
    top:60px;
    z-index:5003;

  }
  .active-listing-details-visible{
  }
  .external{
    background-color: white;
  }
}



.register-clear-svg{
  margin-left:-60px;
  position:absolute;
  right:60px;
  z-index:5001;
}

@media(min-width: $media-mobile) and (max-width: 1520px){
}
@media(max-width: $media-mobile){
  .section-wrapper{
    background-color: rgba(255,255,255,1);
  }

  .universal-search{
    svg.clear-input{
      top:14px;
      right:60px;
    }
  }
}


.universal-search-input-text{
  &::placeholder{
    font-family: HL-Whitney-Book, sans-serif;
    font-size: 16px;
    font-style: normal;
    color:#9e9e9e;
  }
}
.section-with-header{



  .header{
    border-top:none;
  }
}


.universal-search-results-on-desktop{

.section-with-header .header:not(:first-child) {
}
}

.area-icon-name-wrapper{
  display:flex;
  align-items: center;
}

.overlay-bg-search-desktop{
  position:fixed;
  top:0;
  left:0;
  height:100vh;
  z-index:2000;
  width:100vw;
}

.universal-search-input-text.higher{
  z-index:2003;
}
.higher{
  z-index:2003;
}

.pr{
  position:relative;
  width:100%;
  height:0px;
}

.bottom-overlay{
  position:absolute;
  left:0px;
  top:-70px;
  width:100%;
  height:70px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 20%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1));
}
@media(max-width: $media-mobile){
  .universal-search.hidden{
    display:none;
  }
}



</style>

<template>
  <div :style="getStyle">
    <slot></slot>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isMounted: false
    };
  },
  mounted() {
    this.isMounted = true;
  },
  computed: {
    getStyle() {
      return `
        display: flex;
        flex-direction: ${this.flexDirection};
      `;
    }
  },
  props: {
    'flexDirection': {
      type: String,
      default: 'row'
    }
  }
};
</script>

<style scoped lang="scss">
.fr{
  display:flex;
  flex-direction: row;
}

</style>

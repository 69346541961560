import axios from 'axios';
import sfapi from '../src/Salesforce/api';
import {serialize} from "object-to-formdata";
import normalizePropertyTypeName from "../../src/helpers/normalizePropertyTypeName";
import formatPhoneNumber from "./formatPhoneAreaCodeDashes";
export default (data,advisor_id = null,listing = null,passed_lead_source = null) => {
  const p = new Promise(async (resolve, reject) => {
    let prefix = 'https://www.hawaiilife.com';
    let debug = false;
    let adv_id = data.advisor_id ? data.advisor_id : advisor_id;
    let {agent_email} = data;
    if (adv_id) {
      const agent = await axios.get(`${prefix}/api/v3/search/agents/id/${adv_id}`);
      agent_email = agent.data.email;
    }
    //if window is localhost then we debug
    let first_name = data.name.split(' ')[0];
    let last_name = data.name.split(' ').slice(1).join(' ');
    if(!last_name) last_name = "Unknown"
    let apiData = {
      contact: {
        first_name: first_name,
        last_name: last_name,
        name: data.name,
        email: data.email,
        phone: data.phone
      },
      listing: data.listing ? data.listing : null,
      lead_source: data.lead_source,
      source_description: data.source_description,
      form: {
        comments: data.comments,
        preapprove: data.preapprove,
      },
      agent_email: agent_email,
    };
    let debugLeadsInUrl = null;
    if(window.location.search && window.location.search.includes('debugLeads')){
      alert('we are debugging leads. If you want to turn this off, remove the query parameter of debugLeads from the url, and check console logs to see the data');
      debugLeadsInUrl = true;
    }
    let property_type_name = null;
    if(apiData.listing){
      property_type_name = apiData.listing.property_type_name;

      if(Array.isArray(property_type_name) && property_type_name.length){
        property_type_name = property_type_name[0];
      }
      property_type_name = normalizePropertyTypeName(property_type_name,apiData.listing.dwelling_type_name);
    }

    //if we passed in the listing as an arg, and it wasn't in the data.listing, then we set it to the apiData.listing.
    if(listing && !data.listing){
      apiData.listing = listing;
    }

    const l = new sfapi();

    //if there's a listing, then we set the override period to HL Web - Question
    if(apiData.listing){
      apiData.lead_source = 'HL Web - Question';
      passed_lead_source = 'HL Web - Question';
    }
    //if there is an agent email that is customerexperience, then we just remove it and set the lead source to HL Web - Email Message
    if(!agent_email || agent_email === 'customerexperience@hawaiilife.com'){
      passed_lead_source = 'HL Web - Email Message';
      agent_email = null;
      apiData.agent_email = null;
    }

    //if there is a listing, then we set the lead source to HL Web - Question and override the passed lead source
    if(apiData.listing){
      apiData.lead_source = 'HL Web - Question';
      passed_lead_source = 'HL Web - Question';
    }

    if(!agent_email) {
      //if we have a listing, and there's a listing agent, and there isn't already an agent_email set, then we set the agent email to it.
      if (apiData.listing && apiData.listing.listing_agent && apiData.listing.listing_agent.email) {
        l.setAgentEmail(apiData.listing.listing_agent.email);
        agent_email = apiData.listing.listing_agent.email;
        apiData.agent_email = agent_email;
        //again, this is a HL Web - Question so we set it to that once again if for some reason it was set to null
        l.setLeadSource('HL Web - Question');
        passed_lead_source = 'HL Web - Question';
      }
    }
    l.setFirstName(apiData.contact.first_name);
    l.setLastName(apiData.contact.last_name);
    l.setEmail(apiData.contact.email);
    l.setLeadSource(apiData.lead_source);
    l.setFormData(apiData.form);
    l.setComments(apiData.form.comments);
    if(apiData.contact.phone) l.setPhone(apiData.contact.phone);
    l.setAgentEmail(apiData.agent_email);
    if(debug){
      console.log(112,'apiData',apiData);
    }

    if(!apiData.lead_source){
      l.setLeadSource('HL Web - Direct Email');
    }
    if(apiData.listing) {
      l.setListing(apiData.listing);
    }


    l.setWebsource(data.source_url ? data.source_url : window.location.href.substring(0, window.location.href.indexOf('?')));
    //the final override from above gets set here.
    if(passed_lead_source) l.setLeadSource(passed_lead_source);
    try {
      l.create();
    } catch (error) {
      console.log('Error creating lead:', error);
    }
    if (data.preapprove) {
      const lsCopy = l.getLeadSource();
      l.setLeadSource('HL Web - Lending');
      apiData.form.comments = `Help me get pre-approved. \n` + apiData.form.comments;
      l.setComments(apiData.form.comments);
      await l.create();
      console.log('we are in preapprove mode, and we sent a new lead with lead source "HL Web - Lending" and comments saying "Help me get pre-approved."');
      console.log(l.sendData);
      l.setLeadSource(lsCopy);
    }
    resolve(true);
    return;
  });
  return p;
}


<template>
    <div class="autocomplete-search-panel">
        <div class="inner">
            <div class="flex-row-center" style="height:55px;">
              <input type="text" v-model="query" @blur="clearStuff" placeholder="Search by Address, Area or MLS Number" @keyup="inspectKeyUp" class="autocomplete-search-box searchbox" />
              <a @click="search" :style="searchAdditionalStyle" class="search-button-ac"><span>{{searching ? 'Searching...': 'Search'}}</span></a>
            </div>
            <div class="ac-search-results" v-if="results && results.length">
              <div>
                <svg @click.capture="removeSearchResults" class="close-search" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                     viewBox="0 0 96 96" enable-background="new 0 0 96 96" xml:space="preserve">
              <polygon fill="#222222" points="96,14 82,0 48,34 14,0 0,14 34,48 0,82 14,96 48,62 82,96 96,82 62,48 "/>
              </svg>
              </div>
              <div v-for="(items,group) in resultsGrouped">
                <div class="result-items-header"><h4>{{getLabelForResultGroup(group)}}</h4></div>
                <div v-if="group !== 'agent' && items.length" v-for="(result,idx) in items" @click="openUrl(result.slug)" class="ac-search-result" @mouseover="setActiveItem(result)" :class="{'active':idx === activeIndex}" >
                 <span v-html="highlight(result.display_name) + '' + (result.town ? ', ' + result.town : '') + (result.island ? ' ' + result.island : '')"></span><span class="mls-number not-mobile">{{result.mls_id ?  'MLS ' + result.mls_id : "Area" }}  </span>
                </div>
                <div v-if="group == 'agent' && items.length" v-for="(result,idx) in items" @click="openUrl(result.slug)" class="ac-search-result" @mouseover="setActiveItem(result)" :class="{'active':idx === activeIndex}" >
                  <img style="width:50px; height:50px;" :src="result.image" alt="">
                  <span class="agent-name" v-html="highlight(result.display_name) + '<br /><small>' + result.designation.toString() + ' - ' + (result.island ? result.island : '') + ` Hawai'i`"> </span><span class="mls-number not-mobile">{{result.label}}</span>
                </div>
              </div>
        </div>
          <div class="ac-property-detail" v-if="activeItem && activeItem.record_type === 'listing'">
            <img v-if="activeItem.thumb" :src="activeItem.thumb" :alt="activeItem.display_name" />
            <h4 style="margin-bottom:10px;">{{activeItem.display_name}} {{activeItem.district}} {{activeItem.island}}</h4>
            <h4 style="margin-top:10px;">${{price(activeItem.asking_price)}}</h4>
            <div v-html="activeItem.teaser.substring(0,100) + '...'"></div>
            <a :href="activeItem.slug" class="see-button"> See this property</a>
          </div>

        </div>
        </div>
</template>

<script>
    import axios from 'axios';
    import price from '../helpers/price';
    import caseStrings from "../helpers/caseStrings";
    const base = 'https://platform.hawaiilife.com/';
    const url = base + 'api/search/autocomplete';
    const svgSearchIcon = `<svg width="18" fill="white" height="18" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1216 832q0-185-131.5-316.5t-316.5-131.5-316.5 131.5-131.5 316.5 131.5 316.5 316.5 131.5 316.5-131.5 131.5-316.5zm512 832q0 52-38 90t-90 38q-54 0-90-38l-343-342q-179 124-399 124-143 0-273.5-55.5t-225-150-150-225-55.5-273.5 55.5-273.5 150-225 225-150 273.5-55.5 273.5 55.5 225 150 150 225 55.5 273.5q0 220-124 399l343 343q37 37 37 90z"/></svg>`;
    export default {
        name: "AutocompleteSearch",
        data(){
            return {
                query:'',
                rawResults:[],
                lastSearchRan:null,
                activeIndex:-1,
                activeItem:null,
                searching:false,
                svgSearchIcon,
            }
        },
        methods:{
          logInteraction(){
            try {
              window.sendAnalyticsEvent('view', 'component', 'search', 'autocomplete-search', {});
            }catch(e){

            }
          },
          clearStuff(){
            this.activeItem = null;
            this.logInteraction();
          },
          getLabelForResultGroup(group){
            let maps = {listing:'Listing',shape:'Areas of Interest',agent:'Agents'}
            const count = this.resultsGrouped[group];
            if(group === 'listing' && count.length > 1){
              return "Listings"
            }
            return maps[group];
          },
          removeSearchResults(){
            this.rawResults = [];
            this.activeItem = null;
          },
            price,
            getLabelForResult(result){

                if(result.label && result.label.length) return result.label;
                if(result.record_type === 'shape') return 'Area';
                if(result.record_type === 'listing') return 'Listing';
            },
            caseStrings,
           async search(){
                if(!this.query || !this.query.length){
                    this.activeIndex = -1;
                    this.searching = false;
                    return;
                }
                if(this.query.length < 3){
                  this.searching = false;
                  return;
                }
                this.searching = true;
                try {
                  const {data} = await axios.get(url, {params: {search: this.query}});
                  this.rawResults = data;
                  this.searching = false;
                }catch{
                  this.searching = false;
                }
            this.lastSearchRan = new Date().getTime();
            this.activeIndex = -1;
            this.searching = false;
            this.activeItem = null;
           },
            setActiveItem(item){
              this.activeItem = item;
            },
            highlight(text){
                if(text.toLowerCase().includes(this.query.toLowerCase())){
                    return text.replace(this.query,`<span style="text-decoration: underline">${this.query}</span>`);
                }
                return text;
            },
            openUrl(url){
              url += '?ref=homepage-search-embed';
              try{
                window.sendAnalyticsEvent('visit','autocomplete-search','click','view-property',url);
              }catch(e){

              }
              window.location = url;
            },
            inspectKeyUp(e){
                if(e.keyCode === 40){
                    this.activeIndex += 1;
                    this.activeItem = this.rawResults[this.activeIndex];
                    return;
                }
                if(e.keyCode === 38){
                    this.activeIndex += - 1;
                    if(this.activeIndex < -1) this.activeIndex = 0;
                    this.activeItem = this.rawResults[this.activeIndex];
                    return;
                }
                if(e.keyCode === 13){
                    this.openUrl(this.results[this.activeIndex].slug);
                }
               if(!this.query.length){
                   this.rawResults = [];
                   return;
               }
               if(!this.lastSearchRan) this.search();
               if(this.searching) return;
               this.searching = true;
               const d = new Date().getTime();
               const diff = d - this.lastSearchRan;
               if(diff && diff > 500){
                   this.search();
               }else {
                   setTimeout(this.search, 1000);
               }
            }
        },
        computed:{
            results(){
                return this.rawResults;
            },
            searchAdditionalStyle(){
              return {}
            },
            resultsGrouped(){
              let results = {};
              this.rawResults.map(item => {
                if(item.record_type === "shape"){
                  console.log('shape',item.name);
                  let q = this.query.trim().toLowerCase();
                  if(item.name.toLowerCase().includes(this.query.toLowerCase())){
                    if(!results[item.record_type]){
                      results[item.record_type] = [];
                    }
                    results[item.record_type].push(item);
                  }

                }else{
                  if(!results[item.record_type]){
                    results[item.record_type] = [];
                  }
                  results[item.record_type].push(item);
                }

              });
              return results;
            },
            hasRegions(){
              var hasRegions = false;
              this.rawResults.map(item => {
                if(item.record_type === "shape"){
                  hasRegions = true;
                }
              });
              return hasRegions;
            }

        }
    }
</script>

<style lang="scss" scoped>
    .autocomplete-search-panel {
        font-family: "Whitney A", "Whitney B", sans-serif;
        max-width:700px;
        position:relative;
        .searching{
          font-weight:bold;
          color:#222222;
          font-size:16px;
          position:absolute;
          top:-30px;
          left:0px;
        }
        .search-button-ac{
          &:hover{
            cursor: pointer;
            span{
              text-decoration: underline;
            }
          }
            background-color:#12b5ea;
            font-weight:700;
            font-family:"Whitney A","Whitney B",sans-serif;
            text-align: center;
            width:auto;
            min-width:90px;
            height:100%;
            max-height:55px;
            padding-left:15px;
            padding-right:15px;
            color:white !important;
            display:flex;
            align-items: center;
            justify-content: center;
          @media(max-width:520px){
            min-width:80px;
            font-size:14px;
            padding-left:10px;
            padding-right:10px;
          }
        }

        div.flex-row-center{
            display:flex;
            flex-direction: row;
          height:60px;
        }

        .inner {
            margin-top:10px;
            margin-bottom:10px;
            width:100%;
            position:relative;
            ::placeholder {
                color: #666666;
                font-weight: 100;
                font-size: 0.8em;
            }

            @media (max-width: 450px){
                font-size:0.6em;
                max-width:100%;
            }



            .ac-search-results {
              padding-top:20px;
              img{
                max-height:60px;
                margin-right:10px;
              }
              .result-items-header{
                box-sizing: border-box;
                padding-left:10px;
                padding-right:10px;
                text-align: left;

                h4{
                  -webkit-font-smoothing: antialiased;
                  color:#222;
                  @media(max-width: 520px){
                    font-size:17px;
                  }
                  margin-bottom:5px;
                  margin-top:5px;
                  padding-bottom:10px;
                }
              }


                .close-search{
                  position:absolute;
                  top:10px;
                  right:10px;
                  height:20px;
                  width:20px;
                  fill:#222;
                  @media(min-width:520px){
                    height:20px;
                    width:20px;
                    top:10px;
                    right:10px;
                  }
                  &:hover{
                    cursor:pointer;
                  }
                }
                position: absolute;
                height: auto;
                min-height: auto;
                width:100%;
                z-index:9999;
                background-color: rgba(255, 255, 255, 0.98);
                box-shadow: 5px 5px 5px 2px rgba(200, 200, 200, .1);
                margin-top:20px;
                .ac-search-result {
                  -webkit-font-smoothing: antialiased;
                    position:relative;
                    font-size:17px;
                    font-family: "Whitney A", "Whitney B", Helvetica, Arial, sans-serif;
                    display: flex;
                    font-weight:500;
                    flex-direction: row;
                    vertical-align: middle;
                    align-items: center;
                    color:#555555;
                    @media(max-width:520px){
                      font-size:16px;
                    }
                    &:hover{
                        text-decoration: underline;
                        color:#12B5EA;
                        cursor:pointer;
                        font-weight:700;
                    }
                    .result-items-header{
                      h4,h3,h2{
                        font-size:20px;
                        color:#222222 !important;
                      }

                    }
                    &.active {
                        outline: 1px #12B5EA solid;
                        cursor: pointer;
                        span.record-type {
                            background-color: #222;
                            color: white;
                        }
                        text-decoration: underline;
                    }

                    &.active {

                    }
                    span.agent-name{
                      font-size:17px;
                      small{
                        font-size:10px;
                      }
                    }
                    span.mls-number{
                        position:absolute;
                        right:10px;
                        color:#777777;
                        font-size:12px;
                        span{
                        }
                    }

                    position: relative;
                    padding: 10px;
                    padding-right: 50px;

                    box-sizing: border-box;
                    span.record-type {
                        padding: 5px;
                        background-color: #f8f8f8;
                        position: absolute;
                        right: 5px;
                        top:5px;
                        font-size: 0.8em;
                    }
                }
            }

            .autocomplete-search-box {
                box-sizing: border-box;
                font-size: 17px;
                color: #111;
                padding-left:20px;
                @media(max-width:520px){
                  padding-left:10px;
                }
                margin-top:0px;
                height:100%;
                border:none !important;
                width:100%;
                -webkit-border-radius:0px;
                border-radius:0 !important;
                background: white !important;
                &::placeholder{
                  color:#999;
                  font-size:14px;
                  @media(max-width: 520px){
                    font-size:12px;
                    /* testing */
                  }
                }
              &:focus{
                outline:none !important;
              }
            }
        }

      .ac-property-detail{
        text-align: left;
        color:#222222;
        h2,h4{
          color:#333333;
        }
        h4{
          font-size:14px;
        }
        z-index:5000;
        padding:10px;
        padding-top:20px;
        position:absolute;
        left:calc(100% + 10px);
        -webkit-appearance: none;
        top:75px;
        min-width:250px;
        min-height:500px;
        background-color: rgba(255, 255, 255, 0.98);
        min-height:100%;
        img{
          width:100%;
          display:block;
          margin:0 auto;
          margin-bottom:20px;
        }
        height:auto;
        @media(max-width:720px){
          display:none;
        }

        .see-button{
          background-color: #12b5ea;
          font-size:14px;
          text-align: center;
          font-weight: bold;
          display:block;
          color:white !important;
          padding:10px;
          padding-top:10px;
          text-decoration: none;
          margin-top:10px;
          text-transform: uppercase;

        }

      }

    }

    .not-mobile{
      display:none;
      @media(min-width:520px){
        display:block;
      }
    }
    .mobile-only{
      display:none;
      @media(max-width:520px){
        display:block;
      }
    }
</style>

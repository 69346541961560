<template>
  <div>
    <div class="featured-posts" v-if="posts && posts.length">
        <div>
            <div class="flex-row-direction more-link">
            <h2 v-if="!hidetitle" v-html="title"></h2>
            </div>
           <div @click="openBlogPost(mainBlogPost.link)" v-if="mainBlogPost" class="big-image hover-pointer" :style="getBigPostImageStyle(mainBlogPost)">
               <div class="inner-content">
                   <span class="category" v-if="!hideCategoryBlocks">{{categoryOverride ? categoryOverride : mainBlogPost.category}}</span>
                   <h2 v-html="mainBlogPost.title"></h2>
                   <ul style="margin-top:-20px;" class="list"><li><a :href="mainBlogPost.blogAuthorLink">{{mainBlogPost.author}} |</a></li> <li>{{mainBlogPost.pubDateFormat}}</li></ul>
               </div>
           </div>
            <div class="blog-post-collection" :style="getStyleForPostCollection()">
                <div v-for="blogPost in posts.slice(this.postOffsetStart,4)" @click="openBlogPost(blogPost.url)">
                    <a :href="blogPost.url" v-if="!hideCategoryBlocks"><span class="blog-category" target="_blank" v-if="blogPost.category">{{categoryOverride ? categoryOverride : blogPost.category}}</span></a>
                    <div v-if="blogPost.thumb" class="image-bg" :style="getPostImageStyle(blogPost)"></div>
                    <h4 v-html="blogPost.title"></h4>
                    <div class="blog-post-description" v-html="blogPost.desc">
                    </div>
                    <div class="blog-post-summary">
                        <span v-if="blogPost.author"><a :href="blogPost.blogAuthorLink">{{blogPost.author}}</a></span> | <span>{{blogPost.pubDateFormat}}</span>
                    </div>
                </div>
            </div>
            <div class="flex-center padded more-bottom">
                <a :href="url" class="more-link arrow not-mobile">{{morehreftext}}</a>
                <a :href="url" class="more-link arrow mobile-only">More</a>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
    import axios from 'axios';
    import loadPostsFromJSON from '../helpers/loadPostsFromWPJSON';
    import loadPostsFromVR from '../helpers/loadPostsFromVR';
    import Intersect from 'vue-intersect'
    export default {
      name: "FeaturedBlogPosts",
      props: ['title', 'url', 'category', 'src', 'utmsrc', 'hidetitle', 'morehreftext', 'categoryOverride', 'hideCategoryBlocks'],
      data() {
        return {
          posts: [],
          mainPost: null,
          postOffsetStart: 1,
        }
      },
      components: {
        Intersect
      },
      methods: {
        getStyleForPostCollection() {
          if (this.hideCategoryBlocks) {
            return {'margin-top': '30px'};
          }
          return {}
        },
        setIntersected() {
        },
        getPostImageStyle(post) {
          return this.getBigPostImageStyle(post);
        },
        getBigPostImageStyle(post) {

          if (post.thumb && post.thumb.includes('vacations.hawaiilife.com')) {
            return {'background-image': `url('${post.thumb.replace('styles/listing_teasers/public/blog-teaser', 'uploads')}'),url(${post.thumb})`};
          }
          if (!post.thumbLarge || !post.thumbLarge.length) {
            return {'background-image': `url('https://about.hawaiilife.com/wp-content/uploads/2013/01/iStock-903132006-1-1280x720.jpg')`};
          }
          return {'background-image': `url('${post.thumbLarge}'),url('${post.thumbLarge.replace('720', '700')}'),url('${post.thumbLarge.replace('1280', '1200')}'),url('${post.thumbLarge}'),url('${post.thumbDefault}'),url('https://about.hawaiilife.com/wp-content/uploads/2013/01/iStock-903132006-1-1280x720.jpg')`};
        },
        openBlogPost(url) {
          let append = '?utm_source=';
          append += this.utmsrc ? this.utmsrc : 'featured-embedded-blog-post';
          url += append;
          window.open(url, '_blank');
          return false;
        }
      },
      computed: {
        mainBlogPost() {
          if (this.mainPost) return this.mainPost;
          if (this.posts && this.posts[0]) return this.posts[0];
        }
      },
      async mounted() {
        if (this.src == 'vr' || this.src == 'vr-blog') {
          let data = await axios.get(this.url);
          let result = loadPostsFromVR(data.data);
          this.posts = result.allPosts;
          this.postOffsetStart = result.postOffsetStart;
          this.mainPost = result.mainPost;
          return;
        }
        if (this.url) {
          if (this.url.includes('/category/')) {
            //https://about.hawaiilife.com/wp-json/wp/v2/posts?categories=${category}&_embed=wp:featuredmedia
            let category = this.url.split('/category/')[1];
            //remove trailing slash
            category = category.replace('/','');
            const id = await axios.get(`https://about.hawaiilife.com/wp-json/wp/v2/categories?slug=${category}`);
            const category_id = id.data[0].id;
            let params = {
              per_page: 4,
              orderby: 'date',
              order: 'desc',
              categories: category_id,
              "_embed": "wp:featuredmedia"
            };
            let append = '';
            let result = null;
            let data = null;
            if (this.utmsrc) append += '&utm_source=' + this.utmsrc;
            try {
              result = await axios.get('https://about.hawaiilife.com/wp-json/wp/v2/posts', {params: params});
              data = result.data;
            } catch (e) {
              console.log('error', e);
              return {};
            }
            //this.posts = data;
            //this.postOffsetStart = 1;
            //this.mainPost = data[0];
            //return;
            const d = loadPostsFromJSON(data);
            this.posts = d.allPosts;
            this.postOffsetStart = 1;
            this.mainPost = this.posts[0];
          }
          this.$forceUpdate();
        }
      }
    }
</script>

<style lang="scss" scoped>
    @import '../assets/styles.scss';
    @import '../assets/featured-blog-posts.scss';
    @media only screen and (max-width: 820px) {
    }
    div.blog-post-summary{
        a{
            display:inline-block !important;
        }
    }
</style>

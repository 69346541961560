<template>
  <transition name="fade">
  <div class="modal-backdrop" @scroll="scrolled" :class="getClasses" :style="style" @click.stop="closeModal">
    <div class="close-modal"><span class="close-x-wrapper"><close-x v-if="!noX" :close="closeModal"></close-x></span></div>
    <div class="modal-content2" @scroll="scrolled" :class="modalClass ? modalClass : 'small'" @click.stop="() => {}">
      <div class="close-modal-do"><span class="close-x-wrapper"><close-x v-if="!noX" :close="closeModal"></close-x></span></div>
      <div class="wrapper-around-slot" @scroll.stop>
        <transition name="modal-fade">
        <slot>
          </slot>
        </transition>
      </div>
    </div>
  </div>
  </transition>
</template>

<script>
import CloseX from './close-x';
export default {
  props:['closeModal','modalLevelsOpen','noX','modalClass','zIndex','opaque','full','additionalClass'],
  components:{
    CloseX
  },
  name: "Modal",
  methods:{
    onScroll: function(event) {
      console.log('scroll detected: ', event.deltaY);
    },
    scrolled(event) {
      console.log(event);
      event.preventDefault();
    }
  },
  computed:{
    getClasses(){
      var cl = {}
      if(this.opaque) cl['opaque'] = true;
      if(this.additionalClass) cl[this.additionalClass] = true;
      return cl;
    },
    style:function(){
      return {'z-index': this.$props.zIndex ? this.$props.zIndex : 7000 };
    }
  },
  created:function(){
    if(process.browser) document.body.classList.add("modal-open");
  },
  destroyed:function(){
    if(process.browser) document.body.classList.remove("modal-open");
  }
}
</script>

<style lang="scss">
@import '../assets/scss/mixins/no-scrollbar';
@import '../assets/scss/variables/media';
.close-modal{
  position:relative;
  .close-x-wrapper{
    position: fixed;
    top:0px;
    right:0px;
    @media(min-width:$media-mobile){
      display:none;
    }
  }
}
.close-modal-do{
  @media(max-width:$media-mobile){
    display:none;
  }
  @media(min-width:$media-mobile){
    position:relative;
    display:flex;
    .close-x-wrapper{
      position:absolute;
      top:-20px;
      right:-20px;
    }
  }
}

/* Fade-in Transition for Backdrop */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* Fade-in Transition for Modal Content */
.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: transform 0.3s ease, opacity 0.3s ease;
}
.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
  transform: scale(0.9); /* Slight zoom out */
}




</style>
